import React, { useState } from "react";
import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PageHeader from "../components/pageHeader";
import RichTextRenderer from "../utils/richTextRenderer";
import CircularProgress from "@material-ui/core/CircularProgress";

const MembersInfo = ({ location }) => {
  const [loading, setLoading] = useState(true);

  const { membersInfo } = useStaticQuery(
    graphql`
      query {
        membersInfo: contentfulMembersInfo {
          body {
            json
          }
        }
      }
    `
  );

  return (
    <Layout pageTitle="members-info">
      <SEO pageTitle="Πληροφορίες Μελών" />
      <PageHeader
        pageTitle="ΠΛΗΡΟΦΟΡΙΕΣ ΜΕΛΩΝ"
        pageDescription="Σε αυτή τη σελίδα θα βρείτε πληροφορίες που αφορούν τα μέλη της εταιρείας."
        page="Πληροφορίες Μελών"
        location={location}
      />

      <div className="page-wrapper">
        <RichTextRenderer richTextJson={membersInfo.body.json} />

        <h3>Εγγραφή στην Ευρωπαϊκή Εταιρεία Αθηροσκλήρωσης</h3>
        {loading && <CircularProgress disableShrink />}
        <iframe
          title="contact-form"
          id="cognito-iframe"
          onLoad={() => setLoading(false)}
          src="https://services.cognitoforms.com/f/bJPjV7nVCEyycxTGbf1Xjg?id=3"
          style={{
            visibility: loading ? "hidden" : "visible",
            position: "relative",
            width: 1,
            minWidth: "100%",
            width: "100%",
          }}
          scrolling="yes"
          seamless="seamless"
          height="974"
          width="100%"
        ></iframe>
      </div>
    </Layout>
  );
};

export default MembersInfo;
